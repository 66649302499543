<template>
  <div id="ai-services_aiu_quality-estimation">
    <service-template
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :run-disabled="currentExample === null && uploadedImage === null"
      :icon="require('@/assets/images/icons/services/header/AIU-QUA.svg')"
      type="image"
      service="aiu"
    >
      <template v-slot:header>
        {{ $t('services.qualityEstimation.header') }}
      </template>
      <template v-slot:left>
        <carousel>
          <el-image
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            :src="example"
            fit="cover"
            class="example"
            @click="() => onExampleClick(index)"
          />
        </carousel>
        <upload
          :auth="isAuthenticated()"
          :file-size="2"
          :example-file="examples[currentExample]"
          :disabled="loading"
          :handle-remove="onUploadRemove"
          :handle-upload="onUploadSuccess"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.qualityEstimation.header')"
          :loading="loading"
        >
          <div
            v-if="result"
            class="result"
          >
            <img
              :src="resultIcon"
              :alt="
                result.isQualified
                  ? $t('services.qualityEstimation.ok')
                  : $t('services.qualityEstimation.warning')
              "
              class="result-icon"
            >
            <div class="result-text">
              {{
                result.isQualified
                  ? $t('services.qualityEstimation.ok')
                  : $t('services.qualityEstimation.warning')
              }}
            </div>
            <div
              v-if="result.reasons && result.reasons.length > 0"
              class="reject-reasons"
            >
              <div
                v-for="(reason, index) in result.reasons"
                :key="index"
                class="reason"
              >
                {{ reason }}
              </div>
            </div>
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import QualityOk from '@/assets/images/icons/services/result/aiu/quality-ok.svg';
import QualityWarning from '@/assets/images/icons/services/result/aiu/quality-warning.svg';
import Upload from '@/components/Upload/Index.vue';

export default {
  name: 'QualityEstimation',
  components: {
    Carousel,
    GeneratedResult,
    ServiceTemplate,
    Upload,
  },
  data: () => ({
    currentExample: null,
    uploadedImage: null,
    loading: false,
    result: null,
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
    resultIcon() {
      if (this.result) {
        return this.result.isQualified ? QualityOk : QualityWarning;
      }
      return null;
    },
    examples() {
      return [
        `${this.$consts.publicPath}/examples/AIU/QUA/black_cat.jpeg`,
        `${this.$consts.publicPath}/examples/AIU/QUA/fox.jpg`,
        `${this.$consts.publicPath}/examples/AIU/QUA/dark_window.jpeg`,
        `${this.$consts.publicPath}/examples/AIU/QUA/imgs_blurred.jpg`,
        `${this.$consts.publicPath}/examples/AIU/QUA/imgs_high-resolution.jpg`,
        `${this.$consts.publicPath}/examples/AIU/QUA/obama.jpg`,
        `${this.$consts.publicPath}/examples/AIU/QUA/porsche.jpg`,
      ];
    },
  },
  watch: {
    currentExample() {
      this.result = null;
    },
    uploadedImage() {
      this.result = null;
    },
  },
  methods: {
    async run() {
      this.loading = true;
      const formData = new FormData();
      if (this.uploadedImage) {
        formData.append('file', this.uploadedImage);
      } else {
        const imageResponse = await this.$api.aiu.fetchImage(
          this.examples[this.currentExample],
        );
        formData.append('file', imageResponse.data);
      }
      return this.$api.aiu.qualityEstimation.estimateQuality(formData);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result.data;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      if (this.loading) {
        return;
      }

      this.currentExample = index;
      this.uploadedImage = null;
      this.result = null;
    },
    onUploadRemove() {
      this.result = null;
      this.currentExample = '';
      this.uploadedImage = null;
      this.currentExample = null;
    },
    onUploadSuccess(file) {
      this.uploadedImage = file;
    },
  },
};
</script>

<style lang="scss" scoped>
#ai-services_aiu_quality-estimation {
  background: $background-secondary;

  .example {
    height: 100%;
    cursor: pointer;
  }

  /deep/ .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .result-text {
      margin-top: 0.16rem;
      margin-bottom: 0.2rem;
      color: $text-gray;
      font-weight: 500;
      font-size: 0.16rem;
    }

    .reject-reasons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: -0.05rem;

      .reason {
        margin: 0.05rem;
        padding: 0.1rem 0.16rem;
        border-radius: 0.32rem;
        color: $text-error;
        background-color: rgba($text-error, 0.1);
      }
    }
  }
}
</style>
